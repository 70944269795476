<template>
  <b-card>
    <h4 class="d-inline"><i class="fa fa-chart-line"></i> Completions CAPEX Budget VS Actuals</h4>
    <vue-json-to-csv v-if="chartData.length > 0" csv-title="Completions Capex Budget vs Actuals Export" class="btn btn-sm btn-blue float-right"
        :json-data="chartData">
        Download Table CSV/Excel
        <i class="fa fa-download"></i>
    </vue-json-to-csv>
    <div class="row mb-5 clearfix">
      <div class="col-md-6 col-xl-4 mt-3">
        <h5>Select Project</h5>
        <multiselect v-model="projectid" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" @input="getCats()" :taggable="true" label="projectname" :preselect-first="true">
        </multiselect>
      </div>
      <div class="col-md-6 col-xl-4 mt-3">
        <h5>Select Category</h5>
        <multiselect v-model="cats" placeholder="Search for category" tag-placeholder="Search for category" track-by="pcid" :options="catData"  :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" label="category" :preselect-first="true">
        </multiselect>
      </div>
      <div class="col-md-6 col-xl-4 mt-3">
        <h5>Build</h5>
        <b-button class="w-100 mt-1" variant="success" @click="loadInvoices()">
          Build <i class="fa fa-chart-bar"></i>
        </b-button>
      </div>
    </div>
    <div id="invoice-chart">
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
      <div id="white"></div>
      <div id="chartContainer" style="height: 450px; width: 100%;"></div>
    </div>
  </b-card>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'Chart-1',
  components: {
    Multiselect,
    VueJsonToCsv,
    RotateSquare2,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      chartData: [],
      cats: [],
      catData: [],
      categoryid: [],
      loading: false,
      testChartData: []
    }
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    getCats() {
      this.catData = [];
      this.cats = [];
      for (var i = 0; i < this.projectid.length; i++) {
        if(this.$session.get('user') == 'pm'){
          this.$axios.get('/wts/get/cats/' + this.projectid[i].projectid).then(response => {
            this.catData = this.lodash.orderBy(this.lodash.uniqBy(this.catData.concat(response.data.result), 'category'), ['category'], ['asc']);
          })
          .catch(error => {
          })
        }
        else {
          this.$axios.get('/manager/get/cats/' + this.projectid[i].projectid).then(response => {
            this.catData = this.lodash.orderBy(this.lodash.uniqBy(this.catData.concat(response.data.result), 'category'), ['category'], ['asc']);
          })
          .catch(error => {
          })
        }
      }
    },
    async loadInvoices(a){
      if(!this.projectid){
        this.$toasted.show('Error. Please select project.', {type: 'error', duration: '3000'})
        return false;
      }
      if(this.cats.length === 0){
        this.$toasted.show('Error. Please select category.', {type: 'error', duration: '3000'})
        return false;
      }
      this.loading = true;
      this.chartData = [];

      var temp = this.projectid.sort(function(a,b) {
        return new Date(a.p_start_date).getTime() - new Date(b.p_start_date).getTime()
      })[0];

      this.startDate = temp.p_start_date;

      var projectids = this.projectid.map(x=>x.projectid)
      var categorys = this.cats.map(x => x.category)
      var pcids = this.cats.map(x => x.pcid)

      let data = JSON.stringify({
        projectids: projectids,
        categories: categorys,
        pcids: pcids,
        cat_pcids: this.cats,
        start_date: this.$moment(this.startDate).startOf('month').format('YYYY-MM-DD'),
      })

      this.$axios.post('/charts/v2/1', data)
      .then(response => {
        this.createChart(response.data.budget, response.data.actual, response.data.diff)
      })
    },
    createChart(apiData, apiData2, apiData3) {
      var allApiData = [apiData, apiData2, apiData3]
      var allDataWithQuarters = [];
      for (var b = 0; b < allApiData.length; b++) {
        var getChartData = allApiData[b];
        var chartDataWithQuarters = [];
        var chartIndex = 0;

        for (var i = 0; i < getChartData.length; i++) {
          let m = this.$moment(getChartData[i].label).format("M");
          let data = getChartData[i];
          data.x = chartIndex;

          chartDataWithQuarters.push(data)

          if(m % 3 == 0 && i + 1 <= getChartData.length){
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('quarter'), 'month')
              && this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).endOf('quarter'), 'month'))

            var chartDataTemp = {
              "label": "Q" + this.$moment(getChartData[i].label).quarter() + "-" + this.$moment(getChartData[i].label).year(),
            }

            var y0 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[0]
            }, 0);
            var y1 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[1]
            }, 0);
            var y2 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[2]
            }, 0);

            y0 = 0;

            if(b == 2){
              if(allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[0] > allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1]){
                y0 = allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1];
              }
              else {
                y2 = 0;
              }
            }

            chartDataTemp.y = [y0, y2, y2];
            chartIndex++;
            chartDataTemp.x = chartIndex;
            chartDataWithQuarters.push(chartDataTemp)
          }
          if(m % 3 != 0 && i + 1 == getChartData.length){
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('quarter'), 'month')
              && this.$moment(getChartData[i].label).isSameOrBefore(this.$moment(x.label).endOf('quarter'), 'month'))

            var chartDataTemp = {
              "label": "Q" + this.$moment(getChartData[i].label).quarter() + "-" + this.$moment(getChartData[i].label).year(),
            }

            var y0 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[0]
            }, 0);
            var y1 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[1]
            }, 0);
            var y2 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[2]
            }, 0);

            y0 = 0;

            if(b == 2){
              if(allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[0] > allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1]){
                y0 = allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1];
              }
              else {
                y2 = 0;
              }
            }

            chartDataTemp.y = [y0, y2, y2];
            chartIndex++;
            chartDataTemp.x = chartIndex;
            chartDataWithQuarters.push(chartDataTemp)
          }
          if(m == 12 || (i + 1) == getChartData.length){
            console.log('ytd')
            chartDataWithQuarters.push(data)

            var findMonthsInQuarter = getChartData.filter(
              x => this.$moment(getChartData[i].label).isSameOrAfter(this.$moment(x.label).startOf('year'), 'month')
              && this.$moment(getChartData[i].label).isSameOrBefore(this.$moment(x.label).endOf('year'), 'month'))

            var chartDataTemp = {
              "label": "YTD-" + this.$moment(getChartData[i].label).year(),
            }

            var y0 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[0]
            }, 0);
            var y1 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[1]
            }, 0);
            var y2 = findMonthsInQuarter.reduce(function(total, item){
              return total + item.y[2]
            }, 0);

            y0 = 0;

            if(b == 2){
              if(allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[0] > allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1]){
                y0 = allDataWithQuarters[1][allDataWithQuarters[1].length -1].y[1];
              }
              else {
                y2 = 0;
              }
            }

            chartDataTemp.y = [y0, y2, y2];


            chartIndex++;
            chartDataTemp.x = chartIndex;
            chartDataWithQuarters.push(chartDataTemp)
          }

          chartIndex++;
        }

        allDataWithQuarters.push(chartDataWithQuarters)
      }

      console.log(allDataWithQuarters);


      var chart = new CanvasJS.Chart("chartContainer", {
      	animationEnabled: true,
      	exportEnabled: true,
      	title:{
      		text: ""
      	},
      	axisX: {
      	},
      	axisY: {
      		includeZero: false,
          minimum: 0,
      		prefix: "$"
      	},
      	toolTip: {
      		shared: false,
          content: "Date: {label}</br>{breakdown}",
      	},
      	legend: {
      		cursor: "pointer",
      		itemclick: this.toggleDataSeries
      	},
      	data: [
          {
      		type: "rangeColumn",
      		name: "Budget",
      		showInLegend: true,
      		yValueFormatString: "#0.##",
      		dataPoints: allDataWithQuarters[0]
      		},
      		{
      			type: "rangeColumn",
      			name: "Actual",
      			showInLegend: true,
            color: "#9bbb58",
      			yValueFormatString: "#0.##",
      			dataPoints: allDataWithQuarters[1]
      		},
      		{
      			type: "rangeColumn",
      			name: "Actual - Budget (Only Appears On Over Budget)",
      			showInLegend: true,
            color: "#c0504e",
      			yValueFormatString: "#0.##",
      			dataPoints: allDataWithQuarters[2]
          }
      	],
      });
      //console.log(apiData, apiData2, apiData3)

      const that = this;

      this.chartData = [];
      for (var i = 0; i < apiData.length; i++) {
        this.chartData.push({
          "Period": apiData[i].label,
          "Budget": this.formatPrice(apiData[i].y[1]),
          "Actual": this.formatPrice(apiData2[i].y[1]),
          "Actual - Budget": this.formatPrice(apiData[i].y[1] - apiData2[i].y[1]),
        })
      }

      chart.render();
      this.loading = false;
    },
    toggleDataSeries(e) {
    	if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
    		e.dataSeries.visible = false;
    	} else {
    		e.dataSeries.visible = true;
    	}
    	e.chart.render();
    }
  },
  mounted() {
    if(this.$session.get('user') == 'pm'){
      this.$axios.get('wts/get/projects').then(response => {
        this.projectData = response.data.result.reverse()
      })
      .catch(error => {

      })
    }
    else{
      this.$axios.get('manager/get/projects').then(response => {
        this.projectData = response.data.result.reverse()
      })
      .catch(error => {

      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }

  #white {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 15px;
    z-index: 9999;
  }

  #invoice-chart {
    position: relative;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }
    .canvasjs-chart-credit {
      display: none !important;
    }

    #loading-gif {
      z-index: 9999;
      position: absolute;
      top: 200px;
      right: 50%;
    }
  }
</style>
